* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --teal: rgb(0, 128, 128);
  --pale_purple_pantone: rgb(232, 217, 232);
  --powder_blue: rgb(194, 223, 227);
  --cultured: rgb(244, 245, 246);
  --gunmetal: rgb(37, 50, 55);
  --fonts: 'Courier New', Courier, monospace;
}

html {
  /* max-height: 100vh; */
  overflow-x: hidden;
  background-color: var(--cultured);
}

.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

/**********/
/* HEADER */
/**********/

header {
  height: 80px;
  background-color: var(--pale_purple_pantone);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header h1 {
  font-family: var(--fonts);
  color: var(--teal);
  font-size: 36px;
  padding: 10px;
  margin-left: 30px;
  white-space: nowrap;
}

/*******/
/* NAV */
/*******/

nav ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

nav li {
  padding: 10px;
}

nav ul li a {
  font-size: 18px;
  text-decoration: none;
  padding: 10px 15px;
  font-family: var(--fonts);
  font-weight: bolder;
  color: var(--teal);
}

nav ul li a:hover {
  color: var(--pale_purple_pantone);
  background-color: var(--teal);
  border-radius: 50px;
}

/*********/
/* ABOUT */
/*********/

.about-me {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: slide_in_right 1s ease;
}

#about {
  font-family: var(--fonts);
  padding-top: 40px;
  font-size: 38px;
  display: flex;
  justify-content: center;
  color: var(--teal);
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 1080px;
}

.about-content img {
  width: 270px;
  height: auto;
  margin: 20px 40px;
  border: 2px solid var(--gunmetal);
  box-shadow: 6px 6px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50px;
}

.about-content p {
  box-shadow: 6px 6px 8px 0 rgba(0, 0, 0, 0.2);
  border: 2px solid var(--gunmetal);
  background-color: var(--powder_blue);
  color: var(--gunmetal);
  font-family: var(--fonts);
  font-weight: bold;
  padding: 30px;
  margin-right: 40px;
  border-radius: 50px;
  text-align: center;
  font-size: 20px;
  line-height: 1.5em;
}

/***********/
/* CONTACT */
/***********/

.contact-me {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 20vw;
  animation: slide_in_right 1s ease;
}

#contact-title {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: var(--fonts);
  font-size: 38px;
  white-space: nowrap;
  color: var(--teal);
}

#contact-body {
  display: flex;
  flex-direction: column;
  box-shadow: 6px 6px 8px 0 rgba(0, 0, 0, 0.2);
  border: 2px solid var(--gunmetal);
  border-radius: 50px;
  margin-bottom: 20px;
  background-color: var(--powder_blue);
}

.contact-blurb {
  font-family: var(--fonts);
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  line-height: 2;
  padding: 30px;
}

.contact-email {
  font-size: 22px;
  text-decoration: none;
  color: var(--gunmetal);
}

/* .submit-btn {
  font-family: var(--fonts);
  font-weight: bolder;
  font-size: 20px;
  width: 30%;
  margin: 20px 0 20px 40px;
  text-decoration: none;
  padding: 5px 0px;
  outline: none;
  cursor: pointer;
  color: var(--gunmetal);
  border: 1px solid var(--gunmetal);
  border-radius: 50px;
  background-color: var(--pale_purple_pantone);
}

.submit-btn:hover {
  color: var(--pale_purple_pantone);
  background-color: var(--teal);
} */

/* .form-input {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  margin: 0 20px;
  line-height: 1.5em;
}

.form-input label {
  color: var(--gunmetal);
  font-family: var(--fonts);
  font-weight: bolder;
  font-size: 20px;
  padding-bottom: 10px;
}

.form-input input {
  font-family: var(--fonts);
  font-weight: bold;
  font-size: 20px;
  line-height: 2em;
}

.form-input textarea {
  font-family: var(--fonts);
  font-size: 16px;
  font-weight: bold;
  width: 600px;
	height: 120px;
	padding: 5px;
}

.err-text {
  font-family: var(--fonts);
  font-weight: bold;
  font-size: 20px;
  padding: 5px 40px;
} */

/**********/
/* RESUME */
/**********/

.resume-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 20vw;
  margin-bottom: 20px;
  animation: slide_in_right 1s ease;
}

#resume-title {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: var(--fonts);
  font-size: 38px;
  color: var(--teal);
}

.prof-list-container {
  padding: 20px 40px;
  box-shadow: 6px 6px 8px 0 rgba(0, 0, 0, 0.2);
  border: 2px solid var(--gunmetal);
  border-radius: 50px;
  background-color: var(--powder_blue);
}

.prof-list-container a {
  color: var(--gunmetal);
  font-family: var(--fonts);
  font-size: 18px;
  text-decoration: none;
  text-align: center;
}

.prof-list-container a h3 {
  padding: 20px 0px 5px 0px;
}

.prof-list-title {
  color: var(--gunmetal);
  font-family: var(--fonts);
  text-align: center;
  font-size: 28px;
  padding: 0 15px 10px 15px;
  border-bottom: 2px solid var(--gunmetal);
}

.prof-list {
  color: var(--gunmetal);
  padding: 10px 0px;
  list-style: none;
}

.prof-list li {
  font-family: var(--fonts);
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  line-height: 1.5;
}

.resume-download {
  border-top: 2px solid var(--gunmetal);
}

/*************/
/* PORTFOLIO */
/*************/

.portfolio {
  animation: slide_in_right 1s ease;
  margin-bottom: 20px;
}

.portfolio-title {
  font-family: var(--fonts);
  font-size: 38px;
  display: flex;
  justify-content: center;
  margin: 20px;
  margin-top: 40px;
  color: var(--teal);
}

/***********/
/* PROJECT */
/***********/

#project-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.project {
  box-shadow: 6px 6px 8px 0 rgba(0, 0, 0, 0.2);
  border: 2px solid var(--gunmetal);
  color: var(--gunmetal);
  width: fit-content;
  padding: 20px;
  margin: 20px;
  background-color: var(--powder_blue);
  border-radius: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project img {
  position: relative;
  height: 180px;
  width: 300px;
  border: 2px solid var(--gunmetal);
  border-radius: 25px;
}

.project-title {
  font-family: var(--fonts);
  font-size: 26px;
  padding: 20px 0;
  color: var(--gunmetal);
}

.project-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: var(--fonts);
  font-weight: bold;
  border: 2px var(--gunmetal);
  border-style: solid none;
  height: 100px;
  padding: 0px 20px;
  width: 320px;
}

.project-links {
  margin-top: 15px;
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.project-links a {
  font-size: 250%;
  color: black;
}

.project-links .youtube-link {
  font-size: 110%;
}

.project-links div:hover {
  animation: shake 0.4s ease;
}

/**********/
/* FOOTER */
/**********/

footer {
  background-color: var(--pale_purple_pantone);
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 30px;
  height: 80px;
  width: 100vw;
  bottom: 0;
}

footer a {
  color: var(--teal);
  font-size: 40px;
  padding-top: 10px;
}

footer a:hover {
  animation: shaker 0.4s ease;
}

/*****************/
/* MEDIA QUERIES */
/*****************/

@media only screen and (max-width: 885px) {

  /* HEADER / NAV */

  header {
    height: 120px;
    justify-content: center;
    flex-direction: column;
  }

  header h1 {
    margin-left: 0px;
  }

  nav ul {
    margin-bottom: 10px;
  }

  /* ABOUT */

  .about-content {
    flex-direction: column;
    justify-content: center;
  }

  .about-content p {
    margin: 0 40px 30px 40px;
  }

  /* CONTACT ME */

  .form-input {
    line-height: 1;
    padding-bottom: 0px;
  }

  .form-input input {
    line-height: 1.5em;
  }

  .form-input textarea {
    width: 300px;
  }
}

@media only screen and (max-width: 568px) {

  /* HEADER / NAV */

  nav ul {
    justify-content: center;
  }

  nav li {
    padding: 10px 0px;
  }

  nav ul li a {
    padding: 10px 5px;
  }

  /* CONTACT ME */

  #contact-form {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 440px) {

  /* HEADER / NAV */

  nav ul li a {
    font-size: 14px;
  }

  /* ABOUT */

  .about-content {
    max-width: 350px;
  }

  .about-content p {
    font-size: 16px;
  }

  /* CONTACT ME */

  .contact-blurb {
    font-size: 16px;
  }

  .contact-email {
    font-size: 20px;
  }

  .form-input {
    margin: 0px 5px; 
  }

  .form-input textarea {
    width: 250px;
  }

  .err-text {
    font-size: 16px;
  }

  /* PORTFOLIO */

  .project img {
    height: 140px;
    width: 230px;
  }

  .project-desc {
    height: 120px;
    width: 250px;
  }

  .project-links {
    width: 230px;
  }

  /* RESUME */

  .prof-list-container {
    padding: 20px;
  }
}

/*************/
/* KEYFRAMES */
/*************/

@keyframes slide_in_right {
  0% {
    opacity: 0;
    transform: translateX(800px);
  }
  50% {
    opacity: 0.75;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes slide_in_up {
  0% {
    opacity: 0;
    transform: translateY(800px);
  }
  50% {
    opacity: 0.75;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(20deg) scale(1.6);
  }
  66% {
    transform: rotate(-20deg) scale(1.6);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes shaker {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(20deg);
  }
  66% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}